import { ReactNode, useMemo } from "react"
import { range } from "lodash"
import { Col } from "react-bootstrap"

export default function PaginationFrontend({
  pageCount,
  page,
  onChange,
}: {
  page: number
  pageCount: number
  onChange: (paginationNumber: number) => void
}) {
  const items = useMemo(() => {
    const siblingCount = 1
    // Pages count is determined as siblingCount + firstPage + lastPage + page + 2*DOTS
    const totalPageNumbers = siblingCount + 4

    let pages: number[] = []

    // Case 1:
    // if the number of pages is less than the page numbers we want to show in our
    // paginationComponent, we return the range [1..pageCount]
    if (totalPageNumbers >= pageCount) {
      pages = range(1, pageCount + 1)
    }

    // calculating left and right sibling index and make sure they are within range 1 and pageCount
    const leftSiblingIndex = Math.max(page - siblingCount, 1)
    const rightSiblingIndex = Math.min(page + siblingCount, pageCount)

    // not showing dots just when there is just one page number to be inserted
    // between the extremes of sibling and the page limits i.e 1 and pageCount
    // Thus, using leftSiblingIndex > 2 and rightSiblingIndex < pageCount - 2
    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < pageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = pageCount

    // Case 2: No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 2 + 2 * siblingCount
      const leftRange = range(1, leftItemCount)

      pages = [...leftRange, 0, pageCount]
    }

    // Case 3: No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 2 + 2 * siblingCount
      const rightRange = range(pageCount - rightItemCount + 2, pageCount + 1)
      pages = [firstPageIndex, 0, ...rightRange]
    }

    // Case 4: Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex)
      pages = [firstPageIndex, 0, ...middleRange, 0, lastPageIndex]
    }

    const items: ReactNode[] = []
    pages.forEach((i, e) => {
      // if it is not "..."
      if (i !== 0) {
        items.push(
          <div
            className={`item ${page === i ? "selected" : ""}`}
            key={e}
            onClick={() => {
              onChange(i)
            }}
          >
            {i}
          </div>,
        )
      } else {
        items.push(
          <div className={`dots`} key={e}>
            {"..."}
          </div>,
        )
      }
    })

    return items
  }, [pageCount, page, onChange])

  return pageCount > 1 ? (
    <Col>
      <div className={"pagination-frontend-cc"}>
        <div className={`item ${page === 1 ? "disabled" : ""}`} onClick={() => onChange(page - 1)}>
          {"<"}
        </div>
        {items}
        <div className={`item ${pageCount === page ? "disabled" : ""}`} onClick={() => onChange(page + 1)}>
          {">"}
        </div>
      </div>
    </Col>
  ) : (
    <></>
  )
}
