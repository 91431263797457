import { Col, Row } from "react-bootstrap"
import { ColProps } from "react-bootstrap/Col"
import { CompetitionTabs } from "@components/Competition/CompetitionTabs"
import { CalciocomConfigHomeLocalizedData } from "../../../types/calciocomConfigs"
import { useEffect, useMemo, useState } from "react"
import {
  CompetitionSeasonTeamStatsStandingFlat,
  LocalizedCompetitionSeasonTeamStatsWithParameterValue,
} from "../../../types/competitionSeasonTeamStats"
import { CompetitionSeasonTeamParameterStandings } from "@components/CompetitionSeason/CompetitionSeasonTeamParameterStandings"
import { LocalizedCompetitionSeasonTeamParameters } from "../../../types/competitionSeasonTeamParameters"
import { Tabs } from "@components/UI/Tabs"
import { Option } from "../../../types/ui"
import { CompetitionSeasonAPI } from "../../../apis/CompetitionSeasonAPI"

const TEAM_PARAMETERS = [
  {
    category: "Goal e Assist",
    code: "goal-assist",
    parameters: ["GOL_ALL", "GOL_OB", "GOL_B", "GOL_R", "GOL_S", "GOL_X", "ASS_V"],
  },
  {
    category: "Passaggi",
    code: "passes",
    parameters: ["PAS", "PAS_COR", "PAS_L", "PAS_X", "CRS", "CRS_X"],
  },
  {
    category: "Tiri",
    code: "shots",
    parameters: ["TIR", "TIR_S", "TIR_SX", "TIR_W"],
  },
  {
    category: "Cartellini",
    code: "cards",
    parameters: ["CRT_G", "CRT_R"],
  },
  {
    category: "Portieri",
    code: "goalkeepers",
    parameters: ["RIG_P", "PRT", "GOL_S", "PRT_X"],
  },
  {
    category: "Altro",
    code: "other",
    parameters: ["FAL", "PSP_X", "ME_PLRV"],
  },
]

export function CompetitionTeamParameterStandings({
  homeData,
  teamParameterStandings,
  col,
}: {
  homeData: CalciocomConfigHomeLocalizedData[]
  teamParameterStandings: CompetitionSeasonTeamStatsStandingFlat[]
  col?: ColProps
}) {
  const [currentHomeData, setCurrentHomeData] = useState<CalciocomConfigHomeLocalizedData>(homeData[0])
  const [competitionTeamParameterStandings, setCompetitionTeamParameterStandings] =
    useState<CompetitionSeasonTeamStatsStandingFlat[]>(teamParameterStandings)
  const [loading, setLoading] = useState<boolean>(false)
  const [changed, setChanged] = useState<boolean>(false)

  const [parameterCode, setParameterCode] = useState<string | undefined>(TEAM_PARAMETERS[0].parameters[0])
  const [parameterCategory, setParameterCategory] = useState<string | undefined>(TEAM_PARAMETERS[0].code)

  const currentParameter = useMemo(() => {
    let parameter: LocalizedCompetitionSeasonTeamParameters | undefined = undefined
    if (parameterCode) {
      parameter = competitionTeamParameterStandings.map((c) => c.parameter).find((p) => p.code === parameterCode)
    }

    return parameter
  }, [parameterCode, competitionTeamParameterStandings])

  const currentTeamParameterStandings = useMemo(() => {
    let standings: LocalizedCompetitionSeasonTeamStatsWithParameterValue[] = []
    if (parameterCode) {
      standings = competitionTeamParameterStandings.find((c) => c.parameter.code === parameterCode)?.standing || []
    }

    return standings
  }, [parameterCode, competitionTeamParameterStandings])

  const parameters = useMemo(() => {
    const category = TEAM_PARAMETERS.find((t) => t.code === parameterCategory)

    const competitionTeamParameters = competitionTeamParameterStandings.map((c) => c.parameter)

    const parameters: Option[] = []
    if (category) {
      category.parameters.forEach((p) => {
        const parameter = competitionTeamParameters.find((c) => c.code === p)

        if (parameter) {
          parameters.push({
            value: p,
            label: parameter.name,
          })
        }
      })
    }

    setParameterCode(parameters[0]?.value as string | undefined)

    return parameters
  }, [parameterCategory, competitionTeamParameterStandings])

  useEffect(() => {
    if (currentHomeData.competitionSeason && changed) {
      CompetitionSeasonAPI.getHomeTeamParameterStandings(currentHomeData.competitionSeason?.id)
        .then((competitionTeamParameterStandings) => {
          setParameterCategory(TEAM_PARAMETERS[0].code)
          setCompetitionTeamParameterStandings(competitionTeamParameterStandings)
        })
        .catch(() => {
          // console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [currentHomeData, changed])

  return (
    <Col className="competition-team-parameter-standings-cc" {...col}>
      <Row>
        <CompetitionTabs
          competitions={homeData.map((h) => h.competition)}
          selectedCompetitionId={currentHomeData.competition.id}
          onChange={(competitionId) => {
            const newCurrentHomeData = homeData.find((h) => h.competition.id === competitionId)
            if (newCurrentHomeData) {
              setCurrentHomeData(newCurrentHomeData)
              setChanged(true)
            }
          }}
          col={{ lg: 12 }}
        />
        <Tabs
          className={"parameter-category-tabs"}
          selectedId={parameterCategory}
          options={TEAM_PARAMETERS.map((t) => {
            return {
              label: t.category,
              value: t.code,
            }
          })}
          onChange={(parameterCategory) => {
            setParameterCategory(parameterCategory as string)
          }}
          col={{ lg: 12 }}
        />
        <Tabs
          className={"parameter-tabs"}
          selectedId={parameterCode}
          options={parameters}
          onChange={(parameterCode) => {
            setParameterCode(parameterCode as string)
          }}
          col={{ lg: 12 }}
        />
        <CompetitionSeasonTeamParameterStandings
          teamParameterStandings={currentTeamParameterStandings}
          loading={loading}
          col={{ lg: 12 }}
          unitMeasurement={currentParameter?.unitMeasurement}
        />
      </Row>
    </Col>
  )
}
