import React from "react"
import { Col } from "react-bootstrap"
import { ColProps } from "react-bootstrap/Col"
import Image, { StaticImageData } from "next/image"
import style from "./style.module.scss"
import cx from "classnames"

export function NewTitleBanner({
  icon,
  title,
  col,
  isH1 = false,
  isLive = false,
  iconWidth,
  iconHeight,
  noMarginBottom = false,
}: {
  icon?: StaticImageData
  title: string
  col?: ColProps
  isH1?: boolean
  isLive?: boolean
  iconWidth?: number
  iconHeight?: number
  noMarginBottom?: boolean
}) {
  return (
    <Col className={style.titleBanner} style={noMarginBottom ? { marginBottom: "0" } : undefined} {...col}>
      <div className={isLive === true ? cx(style.container, style.sb) : style.container}>
        {icon === undefined ? null : (
          <Image
            className={style.icon}
            src={icon.src}
            alt={title}
            width={iconWidth ? iconWidth : 32}
            height={iconHeight ? iconHeight : 26}
          />
        )}
        {isH1 ? <h1 className={style.title}>{title}</h1> : <h2 className={style.title}>{title}</h2>}
        {isLive ? <div className={style.live}>• Live</div> : null}
      </div>
    </Col>
  )
}
